import React from "react";

export const Hero = ({
  title,
  backgroundImageName,
  backgroundColorName,
  logo
}) => (
  <>
    <div
      className="hero-fluid"
      style={
        backgroundImageName != null
          ? {
              backgroundImage: backgroundImageName
                ? "linear-gradient(180deg, rgba(2,0,36,0) 10%, rgba(18,18,18,1) 75%), url(" +
                  backgroundImageName +
                  ")"
                : "",
              backgroundSize: "cover"
            }
          : {
              background: backgroundColorName
                ? backgroundColorName
                : "rgba(0,0,0,0)",
              backgroundSize: "cover",
              filter: "blur(0px)"
            }
      }
    />
    <div className="post-hero">
      <div
        className="post-title"
        style={{
          display: "flex",
          alignItems: "start",
          flexDirection: "column"
        }}
      >
        {logo != null && (
          <img
            src={logo}
            style={{ height: 100, paddingRight: 25, paddingTop: 15 }}
          />
        )}
        <span>{title}</span>
      </div>
    </div>
  </>
);

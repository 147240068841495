import React from "react";
import { Layout } from "../../../components/Layout";
import { Hero } from "../../../components/Hero";
import { Paragraph } from "../../../components/Paragraph";
import repopRefreshVideo from "../../../assets/videos/repop/repop-refresh.mp4";
import SEO from "../../../components/SEO";

const repop = ({ data }) => (
  <Layout>
    <SEO
      title="repop"
      description="Google Chrome extension that uses
          Javascript and Depop's own API to retrieve and refresh all seller listings."
    />
    <div className="post-container post-full">
      <Hero
        title="repop"
        backgroundColorName="linear-gradient(170deg, red 30%, black 30%, rgba(0,0,0,0) 60%, rgba(0, 0, 0, 0)), linear-gradient(190deg, red 30%, black 30%, rgba(0,0,0,0) 60%, rgba(0, 0, 0, 0))"
      />
      <main className="post-content-container">
        <Paragraph>
          <h1>Making your life selling on Depop.com easier</h1>
          <span>
            If you are a seller on Depop.com then there's one thing you know:
            unless your product is showing up on top of the search, the
            likelihood of you making a sale is very low.
          </span>
        </Paragraph>
        <Paragraph>
          Depop lists new or newly-updated products on top of searches. As such,
          many sellers regularly "refresh" --edit the listing without making any
          changes-- their listings in order to get them more visibility.
        </Paragraph>
        <Paragraph>
          While the process of "refreshing" a listing if very simple, it's not a
          feasible process if you have hundreds of listings. You would be
          spending hours throughout the day manually editing each individual
          listing.
        </Paragraph>
        <Paragraph>
          repop solves that. repop is a Google Chrome extension that uses
          Javascript and Depop's own API to retrieve and refresh all seller
          listings. The best part is it doesn't need user credentials as long as
          the person is already logged in to Depop.com via Google Chrome.
        </Paragraph>
        <Paragraph>Here's a quick look:</Paragraph>
        <br />
        <br />
        <video
          muted
          playsinline
          autoplay
          loop
          controls
          style={{
            height: 500,
            width: "450px",
            maxWidth: "100%",
            alignSelf: "center"
          }}
        >
          <source src={repopRefreshVideo} type="video/mp4" />
        </video>
        <br />
        <br />
        <Paragraph>
          repop is for personal use only. An iOS version is also being worked
          on.
        </Paragraph>
      </main>
    </div>
  </Layout>
);

export default repop;
